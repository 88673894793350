
<template>
  <div class="setpassword content_getresponse !bg-[#F1F2F7]">
    <div class="setpassowrd_inner container !rounded-[16px] !max-w-[30rem] ">

      <div class=" flex flex-col justify-center items-center gap-y-3 ">
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Connect Mautic</p>
        <p class=" text-[14px] font-normal font-poppins text-[#757A8A] text-center">Enter Your login credentials and Site URL</p>
      </div>

      <transition name="slide">
        <div v-if="!show_lists" class="px-8 pb-6 pt-10 flex flex-col justify-center items-center gap-y-3 ">

          <FloatingLabelInput
            id="name"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Mautic UserName"
            placeholder="UserName"
            v-model="username"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="pass"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="password"
            label="Mautic Password"
            placeholder="Password"
            v-model="password"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="url"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Mautic Site URL"
            placeholder="Site URL"
            v-model="url"
          >
          </FloatingLabelInput>
          <div class=" mt-3  flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$router.push({name: 'integrations'})"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              id="API"
              type="button"
              class="px-[2.28rem] py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              :disabled="disable_connect"
              @click.prevent="saveMautic()">
              <template v-slot:label>Connect</template>
            </Button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import { saveMauticURL } from '@/config/config.js'
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    components: {
      FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
      Button: () => import('@/ui/ui-kit/v2/Button.vue')
    },
    data () {
      return {
        username: '',
        url: '',
        password: '',
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,
        expire: null,
        integration_id: null,
        reconnect: false,
        account_id: '',
        name: ''
      }
    },
    async created () {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile', 'getWorkspace'])
    },
    methods: {
      saveMautic () {
        if ((this.username).trim() == '') {
          this.alertMessage('Please enter your Mautic Username.', 'error')
          return
        }
        if ((this.password).trim() == '') {
          this.alertMessage('Please enter your Mautic Password.', 'error')
          return
        } else if ((this.url).trim() == '') {
          this.alertMessage('Please enter your Mautic Site Url.', 'error')
          return
        }
        this.disable_connect = true

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        const integration = this.getIntegrationPayload()
        http.post(saveMauticURL, {
          ...integration,
          username: this.username,
          password: this.password,
          url: this.url,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
                this.disable_connect = false
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      }
      // window.location = saveMauticURL + "?id="+this.key+"&secret="+this.secret+"&url="+this.url
    }
    /* setActiveCampaign(){
                    if ((this.key).trim() == "") {
                        this.alertMessage('Please enter your ActiveCampaign API key.', 'error');
                        return;
                    }
                    else if((this.url).trim() == "")
                    {
                        this.alertMessage('Please enter your ActiveCampaign Connect Url.','error');
                        return;
                    }
                    this.disable_connect = true;
                    http.post(activeCampaignConnectURL, {
                        key: this.key,
                        url:this.url
                    }).then(
                        response => {
                            if (response.data.tokenError && response.data.tokenError == true) {
                                this.alertMessage(response.data.message, 'error');
                                this.disable_connect =false;
                            }
                            else {
                                if(response.data.status)
                                {
                                    this.lists = response.data.lists;
                                    this.show_lists = true;
                                    this.reconnect = response.data.reconnect;
                                    this.account_id = response.data.account_id;
                                    this.name = response.data.name;
                                    this.expire = response.data.expire;
                                    this.integration_id = response.data.integration_id;
                                }
                                else{

                                    this.alertMessage(response.data.message,'error');
                                }
                                this.disable_connect =false;
                            }
                        },
                        response => {
                            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error');
                        });

                },

                saveActiveCampaignAccount(){
                    if(this.selected_lists.length == 0)
                    {
                        this.alertMessage('Please select atleast one list.','error');
                        return
                    }
                    http.post(activeCampaignSaveAccountURL, {
                        key: this.key,
                        url:this.url,
                        selected_lists: this.selected_lists,
                        reconnect:  this.reconnect,
                        account_id: this.account_id,
                        name: this.name,
                        integration_id:this.integration_id,
                        expire:this.expire
                    }).then(
                        response => {
                            if (response.data.tokenError && response.data.tokenError == true) {
                                this.alertMessage(response.data.message, 'error');
                            }
                            else {
                                if(response.data.status)
                                {
                                    this.alertMessage(response.data.message,'success');
                                    this.getUserProfile();
                                    this.$router.push({
                                        name: 'integrations'
                                    });
                                }
                                else{
                                    this.alertMessage(response.data.message, 'error');
                                }
                            }
                        },
                        response => {
                            this.alertMessage('Unknown error occurred, administrator has been notified.','error');
                        });
                }
            }
        } */}
)
</script>
